<template>
	<Layout
		v-if="is_view"
		:user="user"
		:member_info="user"
		:codes="codes"
		:shop_info="shop_info"
		:category_list="category_list"
	/>
	<Loading
		v-else
	></Loading>
</template>

<script>
	import Layout from '@/view/Layout/Layout.vue'
	import {codes} from "@/resources/config/codes";
	import {Base64} from "js-base64";
	import Loading from "@/view/Layout/Loading";

export default {

	name: 'App'
	,components: {Loading, Layout }
	,data: () => ({
		codes: codes
		, user: {

		}
		, shop_info: {

		}
		, items_category: []
		, is_view: false
	})
	, computed: {
		category_list: function(){
			return this.items_category
		}
	}
	,methods: {
		isAuth: function(){
			this.getBaseInfo()
		}

		,getBaseInfo: async function(){
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'main/getBaseInfo'
				})

				if(result.success){
					this.setCode(result.data.code_list)
					let d = result.data.account_info

					if(d){
						d = JSON.parse(Base64.decode(d))
						this.user = d ? d : {}
					}
					this.shop_info = result.data.shop_info
					this.items_category = result.data.category_list

					let skin = "skin-G"
					let skin_img = "/"
					if(this.shop_info.site_skin){
						skin = `skin-${this.shop_info.site_skin}`
						skin_img = `/skin-${this.shop_info.site_skin}/`
					}
					this.$skin.name = skin
					this.$skin.img = skin_img

					if(result.data.last_grade){
						this.$set(this.user, 'last_grade', result.data.last_grade)
					}

					setTimeout( () => {
						this.is_view = true
					}, 100)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				alert(e)
				setTimeout(() => {
					this.$bus.$emit('logout')
				}, 100)
			}
		}
		,setCode: function(code_list){
			let list = {}
			code_list.forEach(function(code){
				let main = list[code.main_code]
				if(!main){
					code.items = []
					list[code.main_code] = code
				}
				list[code.main_code].items.push(code)
			})

			Object.assign(list, this.codes)
			this.$set(this, 'codes', list)
		}
		, to: async function ({name, path, params, query = {}, not_query, hash, type}) {
			// console.log('to params', name, this.$route.name)

			query.a = Math.random()

			let router = this.$router.resolve({ name: name, path: path, params: params, query: query, hash: hash ? '#' + hash : ''})
			// console.log('params', params)
			// console.log('router', router)
			if (!not_query) {
				localStorage.removeItem('query')
				this.query = {}
			} else {
				this.query = query
			}

			if(type == 'href'){
				document.location.href = router.href
			}else{
				await this.$router.push({ path: router.route.fullPath })
			}
		}
		, logout: function(){
			sessionStorage.clear()
			localStorage.removeItem('login_info')
			localStorage.removeItem(process.env.VUE_APP_NAME + 'A')
			setTimeout( () => {
				this.$bus.$emit('to', { name: 'Index', type: 'href'})
			}, 500)
		}
	}
	, created() {

		localStorage.removeItem(process.env.VUE_APP_NAME + 'A')
		localStorage.removeItem(process.env.VUE_APP_NAME + 'N')
		localStorage.removeItem(process.env.VUE_APP_NAME + 'P')

		localStorage.removeItem('DailygiftA')
		localStorage.removeItem('DailygiftN')
		localStorage.removeItem('DailygiftP')

		this.$bus.$on('to', ({ name, path, params, query, not_query, hash, type}) => {
			this.to({ name, path, params, query, not_query, hash, type})
		})
		this.$bus.$on('goBack', () => {
			this.$router.back()
		})
		this.$bus.$on('logout', () => {
			this.logout()
		})

		this.$bus.$on('onErrorImage', (e) => {
			e.target.src = require('@/assets/images/common/empty-image.png')
		})

		this.$bus.$on('setUser', (e) => {
			e.forEach( (item) => {
				this.user[item.key] = item.val
			})
		})
		this.isAuth()

		let host = document.location.host
		let domains = process.env.VUE_APP_DOMAIN_SKIN_O.split('|')

		for(let i = 0; i < domains.length; i++){
			if(host.indexOf(domains[i]) > -1) {
				document.title = 'DailyShop'
				let link = window.document.querySelector("link[rel*='icon']") || window.document.createElement("link");
				link.href = "/favicon_s.ico"

				let description = window.document.querySelector("meta[name*='description']");
				description.content = "모바일,지류 발송은 데일리샵!언제나 어디서든 간편하게, 결제후 발송다양한 기프티콘과 기업전용 쇼핑몰까지! 대량발송도 데일리샵과 함께"

				let og_title = window.document.querySelector("meta[property*='og:title']");
				og_title.content = "데일리 샵"

				let og_image = window.document.querySelector("meta[property*='og:image']");
				og_image.content = "https://daily-shop.co.kr/images/logo_top_s.png"

				let og_description = window.document.querySelector("meta[property*='og:description']");
				og_description.content = "모바일,지류 발송은 데일리샵!언제나 어디서든 간편하게, 결제후 발송다양한 기프티콘과 기업전용 쇼핑몰까지! 대량발송도 데일리샵과 함께"

				let og_url = window.document.querySelector("meta[property*='og:url']");
				og_url.content = "https://daily-shop.co.kr"

				let naver_veri = window.document.querySelector("meta[name*='naver-site-verification']");
				naver_veri.content = "69f98bdbee826ae256774a055d4fea6a049e0021"
				break
			}
		}

		domains = process.env.VUE_APP_DOMAIN_SKIN_Gift.split('|')

		for(let i = 0; i < domains.length; i++){
			if(host.indexOf(domains[i]) > -1) {
				document.title = 'DailyGift'
				let link = window.document.querySelector("link[rel*='icon']") || window.document.createElement("link");
				link.href = "/favicon_gift.ico"

				let description = window.document.querySelector("meta[name*='description']");
				description.content = "모바일,지류 발송은 데일리기프트!언제나 어디서든 간편하게, 결제후 발송다양한 기프티콘과 기업전용 쇼핑몰까지! 대량발송도 데일리기프트와 함께"

				let og_title = window.document.querySelector("meta[property*='og:title']");
				og_title.content = "데일리 기프트"

				let og_image = window.document.querySelector("meta[property*='og:image']");
				og_image.content = "https://daily-gift.co.kr/images/logo_top_gift.png"

				let og_description = window.document.querySelector("meta[property*='og:description']");
				og_description.content = "모바일,지류 발송은 데일리기프트!언제나 어디서든 간편하게, 결제후 발송다양한 기프티콘과 기업전용 쇼핑몰까지! 대량발송도 데일리기프트와 함께"

				let og_url = window.document.querySelector("meta[property*='og:url']");
				og_url.content = "https://daily-gift.co.kr"

				let naver_veri = window.document.querySelector("meta[name*='naver-site-verification']");
				naver_veri.content = "6bc876960f8c1882591bf32ea8c5d74135945131"
				break
			}
		}

		domains = process.env.VUE_APP_DOMAIN_SKIN_T.split('|')

		for(let i = 0; i < domains.length; i++){
			if(host.indexOf(domains[i]) > -1) {
				document.title = 'OnGift'
				let link = window.document.querySelector("link[rel*='icon']") || window.document.createElement("link");
				link.href = "/favicon_on.ico"

				let description = window.document.querySelector("meta[name*='description']");
				description.content = "모바일,지류 발송은 온기프트!언제나 어디서든 간편하게, 결제후 발송다양한 기프티콘과 기업전용 쇼핑몰까지! 대량발송도 온기프트와 함께"

				let og_title = window.document.querySelector("meta[property*='og:title']");
				og_title.content = "온기프트"

				let og_image = window.document.querySelector("meta[property*='og:image']");
				og_image.content = "https://on-gift.com/images/logo_top_gift.png"

				let og_description = window.document.querySelector("meta[property*='og:description']");
				og_description.content = "모바일,지류 발송은 온기프트!언제나 어디서든 간편하게, 결제후 발송다양한 기프티콘과 기업전용 쇼핑몰까지! 대량발송도 온기프트와 함께"

				let og_url = window.document.querySelector("meta[property*='og:url']");
				og_url.content = "https://on-gift.com"

				let naver_veri = window.document.querySelector("meta[name*='naver-site-verification']");
				naver_veri.content = "6bc876960f8c1882591bf32ea8c5d74135945131"
				break
			}
		}
	}
	, watch: {
		$route(){
			document.body.scrollTo({top: 0, behavior: 'smooth'})
		}
	}
};
</script>

<style>


.slide-fade-enter-active {
	transition: all .3s ease;
}
.slide-fade-leave-active {
	transition: all .0s ease;
}
.slide-fade-enter{
	transform: translateY(100%);

	overflow: hidden;
	height: 100%;
}
.slide-fade-leave, .slide-fade-leave-to {
	transform: translateY(100%) ;
	opacity: 0;
	overflow: hidden;
}

@media (max-width: 720px) {

	.icon-15 {
		width: 15px; height: 15px;
		border-radius: 100%;
	}
}

@media (min-width: 720px) {

	.icon-32 {
		width: 32px; height: 32px;
		border-radius: 100%;
	}
}
</style>