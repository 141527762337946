<template>
	<div>

		<div class="container mb-50-pc mb-20-m">
			<Banner>

			</Banner>

			<div class="mt-50-pc mt-10-m justify-space-between gap-20-pc gap-10-m main-menu ">
				<div class="width-25 bg-primary radius-20-pc radius-10-m pa-20-pc pa-10-5-m color-white bg-cash " @click="toPoint">
					<div
						v-if="user.member_level > 0"
						class="justify-space-between size-px-9-m"
					>포인트 충전 <v-icon class="color-white hide-m">mdi-chevron-right</v-icon></div>
				</div>
				<div
					v-if="false"
					class="width-25 bg-primary radius-20-pc radius-10-m color-white hidden" @click="toPoint">
					<div>
						<img :src="require(`@/assets/images${$skin.img}main/icon-charge.png`)" class="width-100"/>
					</div>
				</div>
				<div
					class="width-25 box-hover-primary flex-column justify-center gap-10-pc gap-5-m radius-20-pc radius-10-m size-px-9-m" @click="toList('giftcon')"
				>
					<div>
						<img :src="require(`@/assets/images${$skin.img}main/voucher.png`)" class="width-45-m"/>
					</div>
					<template
						v-if="user.member_level > 0"
					>
						기프트콘

						<div class="hide-m"><button class="btn-round btn-outline btn-m-pc btn-ss-m">바로가기</button></div>
					</template>
				</div>
				<div class="width-25 box-hover-primary flex-column justify-center gap-10-pc gap-5-m radius-20-pc radius-10-m size-px-9-m" @click="toList('')">
					<div>
						<img :src="require(`@/assets/images${$skin.img}main/store.png`)" class="width-45-m" />
					</div>
					일반 상품몰
					<div class="hide-m"><button class="btn-round btn-outline btn-m-pc btn-ss-m">바로가기</button></div>
				</div>
				<div class="width-25 box-hover-primary flex-column justify-center gap-10-pc gap-5-m radius-20-pc radius-10-m size-px-9-m"  @click="toList('business')">
					<div><img :src="require(`@/assets/images${$skin.img}main/mall.png`)" class="width-45-m" /></div>
					기업 상품몰
					<div class="hide-m"><button class="btn-round btn-outline btn-m-pc btn-ss-m">바로가기</button></div>
				</div>

			</div>
		</div>

		<div
			class=" bg-white"
		>
			<div class="ptb-50">
				<div class="container ">
					<div>
						<h2>상품 리스트</h2>
						<div class="ptb-20 size-px-20-pc size-px-14-m">
							<label :class="{'color-primary font-weight-600': search.type == 'recomm'}" @click="getSearch('recomm')">추천 상품</label> | <label :class="{'color-primary font-weight-600': search.type == 'hot'}"  @click="getSearch('hot')">인기 상품</label>
						</div>
					</div>
					<ul
						class="flex-row-wrap justify-start flex-wrap "
					>
						<li
							v-for="(pdt, p_index) in items"
							:key="'pdt_' + p_index"
							class="text-center box box-shadow pt-301 pb-30-pc pb-10-m radius-20 cursor-pointer bg-000 width-25-pdt"

							@click="toDetail(pdt)"
						>
							<div class="prl-301"><img :src="pdt.pdt_img1" @error="$bus.$emit('onErrorImage', $event)" class="object-cover"/></div>
							<hr class="mt-201 mb-10 under-line">
							<div class="prl-30-pc prl-10-m size-px-20-pc size-px-12-m font-weight-600 color-dark text-left line-height-150 flex-column justify-space-between">
								<div class="pdt-box">
									<div v-if="false">[{{ pdt.pdt_category}}]</div>
									<div class="escape">{{ pdt.pdt_name}}</div>
									<div>{{ pdt.pdt_summary}}</div>
								</div>
								<div class="justify-space-between">
									<div></div>
									<div class="size-px-14-pc size-px-9-m font-weight-400 mt-10"><span class="color-error size-px-20-pc size-px-14-m font-weight-bold">{{ pdt.pdt_price | makeComma}}</span>원</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div
			class="mt-50-pc mt-30-m mb-30-pc mb-10-m"
		>
			<div class="container">
				<div class="justify-space-between">
					<h2>공지 사항</h2>
					<button
						class="justify-space-between items-center size-px-16-pc size-px-14-m"
						@click="toNotice"
					>더보기 <v-icon medium>mdi-chevron-right</v-icon></button>
				</div>
				<hr class="bg-primary mt-10 mb-20" style="height: 2px"/>
				<div>
					<ul
						v-if="items_notice.length > 0"
						class="justify-space-between-pc gap-20"
					>
						<li
							v-for="(notice, n_index) in items_notice"
							:key="'notice_' + n_index"
							class="justify-space-between items-center flex-1 mb-10"
							@click="toNotice"
						>
							<div class="text-18 size-px-12-m font-weight-bold justify-start items-center gap-10"><v-icon small class="color-primary size-px-9-m">mdi-checkbox-blank-circle</v-icon> {{ notice.b_title}}</div>
							<div class="text-14 size-px-12-m">{{ notice.wDate }}</div>
						</li>
					</ul>
					<Empty
						v-else
					></Empty>
				</div>
			</div>
		</div>

		<Popup></Popup>
	</div>
</template>

<script>
import Banner from "../Layout/Banner";
import Empty from "../Layout/Empty";
import Popup from "../Layout/Popup";
export default{
	name: 'Main'
	, components: {Popup, Empty, Banner}
	, props: ['user', 'shop_info']
	,data: function(){
		return {
			program: {
				name: process.env.VUE_APP_TITLE_DEV
				,title: false
				,search: true
				,bottom: true
				, is_container: true
			}
			, items: []
			, items_notice: []
			, search: {
				list_cnt: 12
				, type: 'recomm'
			}
		}
	}
	,computed: {
		item_list: function (){

			return this.items.filter((item) => {
				return item
			})
		}
	}
	,methods: {
		getData: async function(){
			this.$bus.$emit('on', true)
			try {
				const result = await this.$request.init({
					method: 'get'
					, url: 'product/getMainProduct'
					, data: this.search
				})

				if (result.success) {

					this.items = result.data.result
					this.items_notice = result.data.notice_list
					this.$set(this.search, 'total_count', result.data.tCnt)
					this.$storage.setQuery(this.search)
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toDetail: function(item){
			this.$bus.$emit('to', { name: 'ProductDetail', params: {idx: item.uid}})
		}
		, toNotice: function(){
			this.$bus.$emit('to', { name: 'CustomerCenter', params: {b_code: 'b_notice'}})
		}
		, toPoint: function(){
			if(this.user.member_level > 0){
				this.$bus.$emit('to', {name: 'PointItem', not_query: false})
			}
		}
		, toList: function(pdt_div){
			if(pdt_div){
				if(this.user.member_level > 0){
					this.$bus.$emit('to', {name: 'ProductList', params: { pdt_div: pdt_div}})
				}else{
					this.$bus.$emit('notify', { type: 'error', message: '회원 전용입니다'})
				}
			}else{
				this.$bus.$emit('to', {name: 'ProductList'})
			}
		}
		, getSearch: function(type){
			this.search.type = type
			this.getData()
		}
	}
	,created: function(){
		this.$bus.$emit('onLoad', this.program)
		this.getData()
	}
}

</script>

<style>

.main-menu > div{
	font-size: 24px;
	font-weight: bold;
	cursor: pointer;
}

@media (min-width: 721px ) and (max-width: 1199px) {
	.main-menu > div{
		font-size: 2vw;
		font-weight: bold;
		cursor: pointer;
	}
}

@media (max-width: 720px) {

	.box-hover-primary {
		border: 1px solid #ddd;
		padding: 10px 5px; text-align: center;
		line-height: 150%;
	}

	.width-25-pdt {
		width: calc(50% - 5px);
		margin-bottom: 10px;
	}

	.width-25-pdt:not(:nth-child(2n)) {
		margin-right: 10px;
	}

	.pdt-box {
		height: 60px
	}
}

@media (min-width: 721px) {

	.box-hover-primary {
		border: 1px solid #ddd;
		padding: 3vw; text-align: center;
		line-height: 150%;
	}

	.width-25-pdt {
		width: calc(25% - 15px);
		margin-bottom: 20px;
	}

	.width-25-pdt:not(:nth-child(4n)) {
		margin-right: 20px;
	}
	.pdt-box {
		height: 90px
	}
}

@media (min-width: 1200px) {

	.box-hover-primary {
		border: 1px solid #ddd;
		padding: 50px; text-align: center;
		line-height: 150%;
	}

	.width-25-pdt {
		width: calc(25% - 15px);
		margin-bottom: 20px;
	}

	.width-25-pdt:not(:nth-child(4n)) {
		margin-right: 20px;
	}
	.pdt-box {
		height: 90px
	}
}

.bg-cash {
	background-image: url('../../assets/images/main/money1.png');
	background-position: bottom right;
	background-size: 80%;
}
</style>